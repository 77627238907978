<template>
  <div class="online-setting fd-w-full fd-h-full fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('onlineSetting.onlineSetting') }}</span>
    </div>
    <span class="fd-text-lg fd-font-bold fd-text-theme-6 fd-mb-3 fd-block">
        {{ $t('secondSidebar.Addresses') }}
    </span>
<!--    <div class="fd-w-full fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">-->
<!--      <label for="address" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">-->
<!--        <span>{{ $t('form.address') }}</span>-->
<!--      </label>-->
<!--      <Boundary @out-click="close">-->
<!--        <div class="fd-w-2/3 fd-relative">-->
<!--          <b-form-input-->
<!--            autocomplete="off"-->
<!--            :max="25"-->
<!--            id="address"-->
<!--            v-model="search"-->
<!--            type="text"-->
<!--            debounce="500"-->
<!--            :placeholder="$t('placeholders.inputNameUser')"-->
<!--          />-->
<!--          <div-->
<!--            v-if="features.length"-->
<!--            class="fd-absolute fd-left-0 fd-top-full fd&#45;&#45;mt-4 fd-w-full fd-px-3 fd-py-4 fd-rounded-lg fd-bg-white fd-border fd-border-theme-5"-->
<!--            style="z-index: 15; box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);"-->
<!--          >-->
<!--            <div-->
<!--              v-for="(feature, index) in features"-->
<!--              :key="index"-->
<!--            >-->
<!--              <span-->
<!--                @click="selectAddress(feature)"-->
<!--                class="fd-block fd-py-1 fd-mt-1 fd-cursor-pointer"-->
<!--              >-->
<!--                {{ feature.place_name }}-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <span class="fd-block fd-text-xs fd-font-normal fd-text-theme-10 fd-mt-1 fd-ml-2">-->
<!--          {{ $t('onlineSetting.max25Characters') }}-->
<!--        </span>-->
<!--        </div>-->
<!--      </Boundary>-->
<!--    </div>-->
    <div class="fd-w-full fd-px-6 fd-py-5 fd-grid fd-items-start" style="grid-template-columns: 1.65fr 3fr .3fr">
      <label class="fd-block fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
        <span>{{ $t('form.locations') }}</span>
      </label>
      <div>
        <div
          v-for="(neighborhood, index) in neighborhoods"
          :key="index"
          class="fd-grid fd-mb-3 fd-gap-3"
          style="grid-template-columns: 1.5fr 2fr"
        >
          <b-form-input
            autocomplete="off"
            v-model="neighborhood.title"
            type="text"
            debounce="500"
            :placeholder="$t('placeholders.inputTitle')"
          />
          <Boundary @out-click="closeNeighborhood(neighborhood)">
            <div class="fd-relative">
              <b-form-input
                autocomplete="off"
                :max="25"
                v-model="neighborhood.address"
                @update="neighborhoodSearch($event, neighborhood)"
                type="text"
                debounce="500"
                :placeholder="$t('placeholders.inputAddress')"
              />
              <div
                v-if="neighborhood.features.length"
                class="fd-absolute fd-left-0 fd-top-full fd-mt-1 fd-w-full fd-px-3 fd-py-4 fd-rounded-lg fd-bg-white fd-border fd-border-theme-5"
                style="z-index: 15; box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);"
              >
                <div
                  v-for="(feature, index) in neighborhood.features"
                  :key="index"
                >
              <span
                @click="selectNeighborhood(neighborhood, feature)"
                class="fd-block fd-py-1 fd-mt-1 fd-cursor-pointer"
              >
                {{ feature.place_name }}
              </span>
                </div>
              </div>
            </div>
          </Boundary>
        </div>
      </div>
      <button
        @click="add"
        class="fd-ml-2 fd-flex fd-items-center fd-justify-center fd-h-11 fd-bg-white fd-border fd-border-theme-4 fd-rounded-lg"
        style="width: calc(100% - 10px)"
      >
        <SvgLoader :name="'plusIcon'" />
      </button>
    </div>
    <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-16 fd-pr-5">
      <b-button
        @click="save"
        variant="primary"
        style="padding-left: 35px !important; padding-right: 35px !important;"
      >
        {{ $t('global.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'
import axios from "axios";
import Boundary from "@/views/components/global/Boundary";
import toast from "@/mixins/toast";
export default {
  name: "Addresses",
  components: { Boundary, BFormInput, BButton },
  mixins: [toast],
  data() {
    return {
      search: '',
      token: 'pk.eyJ1IjoibWlsYWRmYXRoaTAyMSIsImEiOiJja3NzbnlnbnUweWQ0MnF0ZmdsY2I3MDUxIn0.Np1cM_UzkWrIZxJAAWQzsQ',
      types: 'country,region,place,postcode,locality,neighborhood',
      limit: '5',
      features: [],
      neighborhoods: [
        {
          id: 1,
          title: '',
          address: '',
          features: [],
          featureSelected: null
        }
      ],
      dataLoaded: true,
      featureSelected: null
    }
  },
  created() {
    this.dataLoaded = false
    this.$store.dispatch('project/getAddresses', this.$route.params.id).then(response => {
      this.dataPreparation(response)
    }).finally(() => {
      this.$nextTick(() => {
        this.dataLoaded = true
      })
    })
  },
  watch: {
    search: {
      async handler(newVal) {
        if (!newVal) { this.close() }
        if (this.dataLoaded && newVal)
        await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${newVal}.json?limit=${this.limit}&types=${this.types}&language=en-US&access_token=${this.token}`
        )
          .then(response => {
            this.features = response.data.features
          })
      }
    }
  },
  methods: {
    dataPreparation(response) {
      // this.search = response.address
      response.forEach((item, index) => {
        if (index === 0) {
          this.neighborhoods[0].address = item.address
          this.neighborhoods[0].title = item.title
        } else {
          this.add(item)
        }
      })
    },
    selectAddress(feature) {
      this.dataLoaded = false
      this.search = feature.place_name
      this.$nextTick(() => {
        this.dataLoaded = true
      })
      this.featureSelected = feature
      this.close()
    },
    selectNeighborhood(neighborhood, feature) {
      neighborhood.address = feature.place_name
      neighborhood.featureSelected = feature
      this.closeNeighborhood(neighborhood)
    },
    add(data = null) {
      if (data && !('title' in data) && !('address' in data)) {
        data = null
      }
      const lastItem = this.neighborhoods[this.neighborhoods.length - 1]
      this.neighborhoods.push({
        id: lastItem.id + 1,
        title: data ? data.title : '',
        address: data ? data.address : '',
        features: [],
        featureSelected: null
      })
    },
    async neighborhoodSearch(search, neighborhood) {
      await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?limit=${this.limit}&types=${this.types}&language=en-US&access_token=${this.token}`
      )
        .then(response => {
          neighborhood.features = response.data.features
        })
    },
    save() {
      let data = {}
      // if (this.search) { data.address = this.featureSelected }
      if (!this.validateData()) return;
      if (this.neighborhoods.length > 1 || this.neighborhoods[0].title && this.neighborhoods[0].address) {
        data.locations = []
        this.neighborhoods.forEach(item => {
          data.locations.push({
            title: item.title,
            address: JSON.stringify(item.featureSelected)
          })
        })
      }
      if (!Object.keys(data).length) return

      this.$store.dispatch('project/updateAddresses', {
        id: this.$route.params.id,
        data
      }).then(response => {
        this.makeToast(response.data.message)
      })
    },
    close() {
      if (!this.features.length) return
      if (!this.featureSelected) {
        this.search = ''
      }
      this.features = []
    },
    closeNeighborhood(neighborhood) {
      if (!neighborhood.features.length) return
      if (!neighborhood.featureSelected) {
        neighborhood.address = ''
      }
      neighborhood.features = []
    },
    validateData() {
      let BreakException = {};
      let status = true

      try {
        this.neighborhoods.forEach(item => {
          if (!item.address || !item.title) {
            status = false
            throw BreakException;
          }
        })
      } catch (e) {}

      return status
    }
  }
}
</script>

<style scoped>

</style>