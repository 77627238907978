<script>
/**
 * This component is a wrapper that gives this ability
 * to get announced about a component boundary mouse events.
 */
export default {
  props: ['do'],

  mounted() {
    document.addEventListener('click', this.emitOut)
  },

  render() {
    return this.$slots.default[0]
  },

  beforeDestroy() {
    document.removeEventListener('click', this.emitOut)
  },

  methods: {
    emitOut(e) {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        return
      }

      this.$emit('out-click')
    },
  },
}
</script>
